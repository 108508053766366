import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNNSClient} from "../../base/ClientInterface";

export function getShipmentsByScannableIds(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    getNNSClient().getShipmentsByScannableIdsV2(data)
        .then(({success, data}) => {
            if (success && data && data.shipments && data.shipments.length) {
                dispatcher({
                    type: nextAction,
                    data: data,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetShipmentsByScannableIds, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetShipmentsByScannableIds, startTime, true);
            }
        });
}