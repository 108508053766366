import {getShipmentsByScannableId} from "../network/apis/npsw/GetShipmentsByScannableIdAPI";
import {NOTIFICATION_TYPE} from "../constants/Constants";
import {fetchProblemCategoryConfig} from "../handler/ProblemCategoryConfigHandler";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {getShipmentsByScannableIds} from "../network/apis/nns/GetShipmentsByScannableIdsV2API";

export const ScanShipmentsnIntialState = {
    scannedScannableId:"",
    shipmentDetail:{},
    loading: false,
    notificationType: null,
    shipmentDetailFetched: false,
    resetValue: false,
    scanType: "",
    notificationMessageId: "",
    notificationMessageValues: {},
    sectors: []
};

export const SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE = {
    FETCH_SHIPMENT_DETAILS: "FETCH_SHIPMENT_DETAILS",
    SET_SHIPMENT_DETAILS: "SET_SHIPMENT_DETAILS",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    SHOW_DUPLICATE_PACKAGE_NOTIFICATION:"SHOW_DUPLICATE_PACKAGE_NOTIFICATION",
    SHOW_INVALID_CATEGORY_NOTIFICATION:"SHOW_INVALID_CATEGORY_NOTIFICATION",
    SHOW_INVALID_PACKAGE_SCANNED_NOTIFICATION:"SHOW_INVALID_PACKAGE_SCANNED_NOTIFICATION",
    SHOW_INVALID_PACKAGE_LEG_SCANNED_NOTIFICATION:"SHOW_INVALID_PACKAGE_LEG_SCANNED_NOTIFICATION",
    SHOW_INVALID_SHIPMENT_CATEGORY_SCANNED_NOTIFICATION:"SHOW_INVALID_SHIPMENT_CATEGORY_SCANNED_NOTIFICATION",
    RESET_VALUE_DONE:"RESET_VALUE_DONE",
    ERROR: "ERROR"
};

export const ScanShipmentReducer = (state, action) => {
    switch (action.type) {
        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.FETCH_SHIPMENT_DETAILS: {
            if (FeatureManager.isFeatureEnabled(FeatureManager.Features.IS_FEATURE_VALUE_NNS_ENABLED)) {
                getShipmentsByScannableIds({
                    scannableIds: action.data
                }, action.dispatcher, SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SET_SHIPMENT_DETAILS, SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.ERROR)
            } else {
                getShipmentsByScannableId({
                    scannableIds: action.data
                }, action.dispatcher, SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SET_SHIPMENT_DETAILS, SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.ERROR)
            }
            return {...state, loading: true, isError: false, shipmentDetailFetched: false, scanType: action.scanType, notificationType: null}
        }
        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SET_SHIPMENT_DETAILS: {
            return {...state, scannedScannableId: action.data.shipments[0].scannableId, shipmentDetail: action.data.shipments,
                loading: false, shipmentDetailFetched: true, resetValue: true, problemCategoryConfig: fetchProblemCategoryConfig(),
                sectors: action.data.stationInfo && action.data.stationInfo.sectors ? action.data.stationInfo.sectors : []
            }
        }
        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.ERROR: {
            return {...state, loading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"package_not_found", resetValue: true};
        }

        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.CLOSE_NOTIFICATION: {
            return {...state, notificationType: null};
        }
        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_DUPLICATE_PACKAGE_NOTIFICATION: {
            return {...state, notificationType: NOTIFICATION_TYPE.WARNING,
                notificationMessageId: "package_duplicate_scan", resetValue:true};
        }

        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_CATEGORY_NOTIFICATION: {
            return {...state, shipmentDetailFetched: false, notificationType: NOTIFICATION_TYPE.WARNING,
                notificationMessageId: "category_solve_not_found", resetValue:true};
        }

        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_PACKAGE_SCANNED_NOTIFICATION: {
            return {...state, shipmentDetailFetched: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: action.data.notification, notificationMessageValues:
                    {trackingId: action.data.scannableId, problemCategory: action.data.problemCategory}, resetValue:true};
        }

        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_PACKAGE_LEG_SCANNED_NOTIFICATION: {
            return {...state, shipmentDetailFetched: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: action.data.notification, notificationMessageValues:
                    {trackingId: action.data.scannableId, destinationDomain: action.data.destinationDomain}, resetValue:true};
        }

        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_SHIPMENT_CATEGORY_SCANNED_NOTIFICATION: {
            return {...state, shipmentDetailFetched: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: action.data.notification, notificationMessageValues:
                    {trackingId: action.data.scannableId, shipmentCategory: action.data.shipmentCategory,
                    currentShipmentCategory: action.data.currentShipmentCategory}, resetValue:true};
        }

        case SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.RESET_VALUE_DONE: {
            return {...state, resetValue:false};
        }
        default:
            return state
    }
}