import getShipmentByScannableIdResponse from "../__mocks__/GetShipmentByScannableIdResponse.json"
import getCategoriesForNodeId from "../__mocks__/GetCategoriesforNodeId.json"
import getExceptionShipmentSummaryDetails from "../__mocks__/GetExceptionShipmentSummaryDetails.json"
import updateExceptionShipmentsResponse from "../__mocks__/UpdateExceptionShipmentsResponse.json"
import getFeatureValue from "../__mocks__/GetFeatureValue.json"
import getPackageAttribute from "../__mocks__/GetPackageAttribute.json"
import updateExceptionShipmentsForMultipleProblemCategory from "../__mocks__/updateExceptionShipmentsForMultipleProblemCategory.json"
import getStations from "../__mocks__/GetStations";
import searchTransporter from "../__mocks__/SearchTransporters";
import unassignRoute from "../__mocks__/UnassignRoute.json"
import getStagingAreaForPickList from "../json/getStagingAreaForPickList"
import scrubPackage from "../__mocks__/ScrubPackage.json"
import getExceptionShipmentSummary from "../__mocks__/GetExceptionShipmentSummary.json"
import getExceptionShipmentSummaryWithoutShipDetails from "../__mocks__/GetExceptionShipmentSummaryWithoutShipDetails.json"
import {NPSWClient as NPWSClientDelegate} from "./NPSWClient";

export const NPSWClient = {

    getCategoriesForNodeId: (args) =>{
        console.log("Request: getCategoriesForNodeId, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getCategoriesForNodeId })
            },500)
        })
    },

    getPackageLabel: (args) => {
        console.log("Request: getPackageLabel, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: "i am dummy label" })
            },500)
        })
    },

    getExceptionShipmentSummaryDetails: (args) =>{
        console.log("Request: getExceptionShipmentSummaryDetails, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getExceptionShipmentSummaryDetails})
            },2500)
        })
    },

    getFeatureValue: (args) =>{
        console.log("Request: getFeatureValue, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getFeatureValue})
            },500)
        })
    },

    getShipmentsByScannableId: (args) =>{
        console.log(getShipmentByScannableIdResponse[args.scannableIds])

        return new Promise( (resolve) => {
            setTimeout(() => {
                if(getShipmentByScannableIdResponse[args.scannableIds]) {
                    resolve({success: true, data: getShipmentByScannableIdResponse[args.scannableIds]})
                } else {
                    resolve({success: false})
                }
            },2000)
        })
    },

    updateExceptionShipments: async (args, meta, exceptionScannableIdMap) => {
        console.log("Request: updateExceptionShipments, Args: ", args, meta, exceptionScannableIdMap);
        return new Promise( (resolve) => {
            setTimeout(() => {
                let updateExceptionShipmentResponses =[]
                args.scannableIdList.forEach( (scannableId) => {
                    updateExceptionShipmentResponses.push(updateExceptionShipmentsResponse[scannableId])
                })
                resolve({success: true, data: {updateExceptionShipmentResponses}})
            },1000)
        })
    },

    updateExceptionTransporter: async (args) => {
        console.log("Request: updateExceptionTransporter, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                let updateExceptionShipmentResponses =[]
                updateExceptionShipmentResponses.push(updateExceptionShipmentsResponse["TSTKW8QA3Y833SH"])
                resolve({success: true, data: {updateExceptionShipmentResponses}})
            },2000)
        })
    },

    getPackageAttribute: (args) =>{
        console.log("Request: getPackageAttribute, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getPackageAttribute })
            },1000)
        })
    },

    updateExceptionShipmentsForMultipleProblemCategory: (args) => {
        console.log("updateExceptionShipmentsForMultipleProblemCategory", args);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: updateExceptionShipmentsForMultipleProblemCategory })
                },1000)
        })
    },

    scrubPackage: (args) => {
        console.log("Request: scrubPackage, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: scrubPackage })
            },1000)
        })
    },

    getStationList: async () => {
        console.log("Request: getStationList");
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getStations })
                },1000)
        })
    },
    getExceptionShipmentSummary: (args) =>{
        console.log("Request: getExceptionShipmentSummary, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getExceptionShipmentSummary })
            },1000)
        })
    },

    searchTransporter: async () => {
        console.log("Request: searchTransporter");
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: searchTransporter })
                },1000)
        })
    },

    getExceptionShipmentSummaryWithoutShipDetails: (args) =>{
        console.log("Request: getExceptionShipmentSummaryWithoutShipDetails, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getExceptionShipmentSummaryWithoutShipDetails })
            },1000)
        })
    },

    unassignRoute: (args) => {
        console.log("Request: Unassign Route, Args: ", args);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: unassignRoute })
            },1000)
        })
    },

    getProblemSolveCategoryMap: async (args) => {
        console.log("Request: getProblemSolveCategoryMapAPI, Args: ", args);
        return NPWSClientDelegate.getProblemSolveCategoryMap(args);
    },

    getStagingAreaForPickList: async (args) => {
        console.log("Request: getStagingAreaForPickList, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getStagingAreaForPickList })
            },500)
        })
    },
}