import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNNSClient} from "../../base/ClientInterface";

export function GetPackageAttributeAPIV2(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    getNNSClient().getPackageAttribute(data)
        .then(({success, data}) => {
            if (success) {
                console.log(data);
                dispatcher({
                    type: nextAction,
                    data: data.asinToURLMap
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetPackageAttributes, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetPackageAttributes, startTime, true);
            }
        });
}
