import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNNSClient} from "../../base/ClientInterface";

export function getCategoriesForNodeIdAPIV2(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    getNNSClient().getCategoriesForNodeId()
        .then(({success, data}) => {
            if (success) {
                dispatcher({
                    type: nextAction,
                    data: data.configCategoriesMap,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetCategoriesForNodeId, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetCategoriesForNodeId, startTime, true);
            }
        });
}